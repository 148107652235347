<template>
	<v-card-actions class="action-box">
		<v-btn
			large
			icon
			width="20"
			height="20"
			class="ml-1"
			@click="toggleLike"
		>
			<!-- <v-icon color="pink">mdi-heart</v-icon> -->
			<IconZzim v-if="isLiked === false" />
			<IconZzimFill v-else />
			<!-- <v-img width="20" height="20" :src="zzimSrc"></v-img> -->
		</v-btn>
		<div class="body-2 mr-5 ml-1" @click="toggleLike">
			좋아요 {{ comma(loveitCount) }}
		</div>
		<!-- <v-spacer></v-spacer> -->
		<v-btn text class="px-0 mr-5" @click="toComment">
			<IconComment />
			<!-- <v-icon>mdi-message-text</v-icon> -->
			<div class="body-2">댓글 {{ comma(replyCount) }}</div>
		</v-btn>
		<v-bottom-sheet v-model="sns">
			<template v-slot:activator="{ on }">
				<v-btn text v-on="on" class="px-0 mr-5">
					<!-- <v-icon>mdi-share-variant</v-icon> -->
					<IconShareAlt />
					<div class="body-2">공유</div>
				</v-btn>
			</template>
			<v-sheet class="share-box text-center">
				<div class="share-top">공유하기</div>
				<v-container class="share-body lighten-5">
					<v-row no-gutters style="flex-wrap: nowrap">
						<v-col cols="3" class="flex-grow-0 flex-shrink-0">
							<v-btn
								icon
								dark
								width="56"
								height="104"
								@click="shareKakaoTalk()"
							>
								<v-img
									max-width="56"
									src="../icons/icon_kakaotalk.png"
								></v-img>
								<div class="txt-share">카카오톡</div>
							</v-btn>
						</v-col>
						<v-col cols="3" class="flex-grow-0 flex-shrink-0">
							<v-btn
								icon
								dark
								width="56"
								height="104"
								@click="shareKakaoStory()"
							>
								<v-img
									max-width="56"
									src="../icons/icon_kakaostory.png"
								></v-img>
								<div class="txt-share">카카오스토리</div>
							</v-btn>
						</v-col>
						<v-col cols="3" class="flex-grow-0 flex-shrink-0">
							<v-btn
								icon
								dark
								width="56"
								height="104"
								@click="shareLine()"
							>
								<v-img
									max-width="56"
									src="../icons/icon_line.png"
								></v-img>
								<div class="txt-share">라인</div>
							</v-btn>
						</v-col>
						<v-col cols="3" class="flex-grow-0 flex-shrink-0">
							<v-btn
								icon
								dark
								width="56"
								height="104"
								class=""
								@click="shareSMS()"
							>
								<v-img
									max-width="56"
									src="../icons/icon_sms.png"
								></v-img>
								<div class="txt-share">SMS</div>
							</v-btn>
						</v-col>
					</v-row>
					<v-row no-gutters style="flex-wrap: nowrap">
						<v-col cols="3" class="flex-grow-0 flex-shrink-0">
							<v-btn
								icon
								dark
								width="56"
								height="104"
								@click="shareFacebook()"
							>
								<v-img
									max-width="56"
									src="../icons/icon_facebook.png"
								></v-img>
								<div class="txt-share">페이스북</div>
							</v-btn>
						</v-col>
						<v-col cols="3" class="flex-grow-0 flex-shrink-0">
							<v-btn
								icon
								dark
								width="56"
								height="104"
								@click="shareTwitter()"
							>
								<v-img
									max-width="56"
									src="../icons/icon_twitter.png"
								></v-img>
								<div class="txt-share">트위터</div>
							</v-btn>
						</v-col>
						<v-col cols="3" class="flex-grow-0 flex-shrink-0">
							<v-btn
								icon
								dark
								width="56"
								height="104"
								class=""
								@click="shareLink()"
							>
								<v-img
									max-width="56"
									src="../icons/icon_copy.png"
								></v-img>
								<div class="txt-share">URL복사</div>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>

				<!-- <v-list-item
					v-if="appInfo.ver != ''"
					link
					class="share-other text-left"
					@click="shareEtcApp()"
				>
					<v-list-item-title>다른 앱 선택하기</v-list-item-title>
					<v-list-item-icon>
						<IconArrowRight />
					</v-list-item-icon>
				</v-list-item> -->
			</v-sheet>
		</v-bottom-sheet>
		<v-snackbar
			v-model="urlCopy"
			:timeout="2000"
			:value="true"
			absolute
			bottom
			dark
			color="#fff"
			left
			text
			class="snackbar-noti1"
		>
			<!--  하단에서 위치를 조정하려면 class 추가 :: mb-1 (숫자1은 4px)-->
			<v-img
				max-height="24"
				max-width="24"
				class="mr-4"
				src="../icons/checkmark_fill.png"
			></v-img>
			<div>URL이 복사되었습니다.</div>
		</v-snackbar>
		<BeFamilyDialog
			:beFamilyDialog="beFamilyDialog"
			:channelName="channelInfo.title"
			@closeFamilyDialog="closeFamilyDialog"
		/>
		<LoginDialog
			:loginDialog="loginDialog"
			:channel="channelInfo.code"
			@closeLoginDialog="closeLoginDialog"
		/>
	</v-card-actions>
</template>

<script>
import { getCount, getLoveArticle, doLoveArticle } from "@/api";
import BeFamilyDialog from "../common/FamilyDialog.vue";
import LoginDialog from "../common/LoginDialog.vue";
import IconZzim from "../icons/svg/card_zzim.svg";
import IconZzimFill from "../icons/svg/card_zzim_fill.svg";
import IconComment from "../icons/svg/comment.svg";
import IconShareAlt from "../icons/svg/share_alt.svg";
// import IconArrowRight from "../icons/svg/chevron_right.svg";

export default {
	name: "CommentAction",
	components: {
		BeFamilyDialog,
		LoginDialog,
		IconComment,
		IconShareAlt,
		// IconArrowRight,
		IconZzim,
		IconZzimFill,
	},
	props: {
		sk: String,
		images: Array,
		message: String,
	},
	data() {
		return {
			loveitCount: 0,
			replyCount: 0,
			sns: false,
			isLiked: false,
			// 다른 앱 선택하기 등 앱인지 여부 / os 정보에 따라
			appInfo: this.$store.state.appInfo,
			urlCopy: false,
			doLoveLoading: false,
			beFamilyDialog: false,
			loginDialog: false,
		};
	},
	computed: {
		channelInfo() {
			return this.$store.state.channel.items.find(
				(e) => e.code == this.$route.params.channel
			);
		},
		countCheck() {
			return this.$store.state.countCheck;
		},
		url() {
			let path = this.$route.path;
			if (path.split("/").length === 4)
				path = path.substring(0, path.lastIndexOf("/"));
			return `${window.location.origin}${path}/${this.sk}`;
		},
		shareMsg() {
			return `[GS SHOP 공유하기] ${this.channelInfo.title}`;
		},
	},
	watch: {
		countCheck: function () {
			if (
				this.$store.state.countCheck &&
				this.replyCount != this.$store.state.article[this.sk].replyCount
			) {
				this.replyCount =
					this.$store.state.article[this.sk].replyCount || 0;
				this.$store.state.countCheck = false;
			}
		},
	},
	methods: {
		async toggleLike() {
			if (this.loginFlag == false) {
				this.loginDialog = true;
				return;
			} else if (
				this.$store.state.myInfo.family[
					window.location.pathname.split("/")[2]
				] != "Y"
			) {
				this.beFamilyDialog = true;
				return;
			}

			//좋아요 버튼 연속 클릭 방지
			if (this.doLoveLoading) {
				return;
			}
			this.isLiked = !this.isLiked;

			this.doLoveLoading = true;
			const result = await doLoveArticle(this.sk);
			if (result != true && result != false) {
				this.isLiked = !this.isLiked;
				return;
			}
			this.loveitCount += this.isLiked ? 1 : -1;
			this.doLoveLoading = false;
			this.logWrite(
				"doLove",
				"Click_커뮤니티_좋아요" + (this.isLiked ? "" : "_취소"),
				{
					channel: this.$route.params.channel,
					article: this.sk,
				}
			);
			this.clickTrac("424843", this.$route.params.channel);
		},
		toComment() {
			if (
				this.$store.state.myInfo.family[
					window.location.pathname.split("/")[2]
				] == "Y"
			) {
				this.$router.push("/comment/" + this.sk);
			} else if (this.loginFlag == false) {
				this.loginDialog = true;
			} else {
				this.beFamilyDialog = true;
			}
		},
		closeFamilyDialog() {
			this.beFamilyDialog = false;
		},
		closeLoginDialog() {
			this.loginDialog = false;
		},
		shareLog(kind) {
			this.logWrite("doShare", "Click_커뮤니티_공유_" + kind, {
				channel: this.$route.params.channel,
				article: this.sk,
			});
			this.clickTrac("424845", this.$route.params.channel);
		},
		shareKakaoTalk() {
			this.shareLog("카카오톡");
			// 채널 대표 이미지
			const channelCode = this.$route.params.channel;
			let imgCode = "";
			switch (channelCode) {
				case "420782":
					imgCode = "01";
					break;
				case "416860":
					imgCode = "02";
					break;
				case "416861":
					imgCode = "03";
					break;
				case "416862":
					imgCode = "04";
					break;
				default:
					break;
			}
			// 이미지 절대 경로 설정 필요
			const mainImage = this.images[0]
				? this.images[0]
				: `pgm_share_thum_${imgCode}.jpg`;
			const desc =
				this.message.length > 20
					? this.message.substring(0, 20) + "..."
					: this.message;
			window.Kakao.Share.sendDefault({
				objectType: "feed",
				content: {
					title: this.shareMsg,
					description: desc,
					imageUrl: mainImage,
					link: {
						mobileWebUrl: this.url,
					},
				},
				buttons: [
					{
						title: "웹으로 보기",
						link: {
							mobileWebUrl: this.url,
						},
					},
					{
						title: "앱으로 보기",
						link: {
							mobileWebUrl: `http://m.gsshop.com/product/appRedirect.gs?rtnUrl=${this.url}?`,
						},
					},
				],
				social: {
					likeCount: this.loveitCount,
					commentCount: this.replyCount,
				},
			});
		},
		shareKakaoStory() {
			this.shareLog("카카오스토리");
			window.Kakao.Story.open({
				url: this.url,
				text: this.shareMsg,
				install: true,
			});
		},
		shareLine() {
			this.shareLog("라인");
			location.href =
				"http://line.me/R/msg/text/?" +
				encodeURIComponent(this.shareMsg + "\n" + this.url);
		},
		shareSMS() {
			this.shareLog("SMS");
			const userAgent = navigator.userAgent;
			const iosVersion = parseInt(
				userAgent.slice(
					userAgent.indexOf("OS ") + 3,
					userAgent.indexOf("_")
				)
			);

			const msg = this.shareMsg + " " + this.url;
			if (/android/i.test(userAgent)) {
				// Android
				location.href = `sms:?body=${encodeURIComponent(msg)}`;
			} else if (iosVersion < 8) {
				// iOS 8 미만
				location.href = `sms:;body=${encodeURIComponent(msg)}`;
			} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				// iOS 8 이상
				location.href = `sms:&body=${encodeURIComponent(msg)}`;
			} else {
				// 기타, pc
				window.prompt("복사하신 후 문자에 붙여넣기 하세요.", msg);
			}
		},
		shareFacebook() {
			this.shareLog("페이스북");
			window.open(
				"http://facebook.com/sharer.php?t=" +
					encodeURIComponent("안녕하세요") +
					"&u=" +
					encodeURIComponent(this.url)
			);
		},
		shareTwitter() {
			this.shareLog("트위터");
			window.open(
				"https://twitter.com/intent/tweet?text=" +
					encodeURIComponent(this.shareMsg) +
					"&url=" +
					encodeURIComponent(this.url)
			);
		},
		shareLink() {
			this.shareLog("복사");
			if (navigator.clipboard != undefined) {
				navigator.clipboard
					.writeText(this.url)
					.then(() => {
						this.snackbar(
							"commonSnackbar",
							"URL이 복사되었습니다."
						);
					})
					.catch((err) => {
						console.log("Was not possible to copy the text: ", err);
					});
			}
			this.sns = false;
		},
	},
	// shareEtcApp() {
	// 	location.href =
	// 		"toapp://share?target=etc&link=" +
	// 		"http://m.gsshop.com/product/appRedirect.gs?rtnUrl=" +
	// 		window.btoa(this.url);
	// },
	async created() {
		// 준(?)실시간성 데이터로 클라이언트에서는 매번 호출하지만 API Gateway의 캐시설정을 통해 처리 필요
		const count = await getCount(this.sk);
		if (!this.$store.state.article[this.sk]) {
			this.$store.state.article[this.sk] = {};
		}
		this.loveitCount = count.love;
		this.replyCount = count.reply;
		this.$set(
			this.$store.state.article[this.sk],
			"loveitCount",
			count.love
		);
		this.$set(
			this.$store.state.article[this.sk],
			"replyCount",
			count.reply
		);
		this.isLiked = await getLoveArticle(this.sk);
	},
};
</script>

<style lang="scss" scoped>
.action-box::v-deep {
	color: #191923;
	padding: 8px 16px;
	.v-btn--icon {
		color: #191923;
	}
}

.v-bottom-sheet {
	display: block;
	opacity: 0;
}

// 공유하기 레이어
.share-box::v-deep {
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	.share-top {
		position: relative;
		padding: 30px 0 10px;
		font-size: 16px;
		font-weight: 600;
		color: #191923;
		&:before {
			content: "";
			position: absolute;
			top: 8px;
			left: 50%;
			margin-left: -20px;
			display: block;
			width: 40px;
			height: 4px;
			border-radius: 4px;
			background-color: rgba(149, 149, 178, 0.3);
		}
	}
	.share-body {
		.txt-share {
			margin-top: 8px;
			font-size: 12px;
			color: #191923;
		}
		.v-btn__content {
			flex-direction: column;
		}
	}
	.share-other {
		margin: 0 16px;
		background-color: #f9fafc;
		border-radius: 10px;
	}
}

.snackbar-noti1::v-deep {
	z-index: 300;
	position: fixed;
	bottom: 10px;
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		margin: 16px;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
	}
}
.v-btn:not(.v-btn--round).v-size--default {
	min-width: auto;
}
</style>
