<template>
	<v-dialog v-model="getFlag" persistent max-width="290">
		<v-card class="popup-box">
			<v-card-text class="pt-6">
				가족이 되시면 등록된 모든 게시글을 확인하고 댓글을 입력하실 수
				있습니다. {{ channelName }}의 가족이 되어주세요!
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="btn-confirm" text @click="beFamily">
					가족되기
				</v-btn>
				<v-btn color="btn-confirm" text @click="unsetDialog">
					닫기
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>
// import IconArrowRight from "../icons/svg/chevron_right.svg";

export default {
	name: "BeFamilyDialog",
	components: {},
	props: {
		channelName: String,
		beFamilyDialog: Boolean,
	},
	data() {
		return {
			dailogFlag: false,
		};
	},
	computed: {
		getFlag() {
			return this.beFamilyDialog;
		},
	},
	methods: {
		unsetDialog() {
			this.$emit("closeFamilyDialog", false);
		},
		beFamily() {
			this.$emit("closeFamilyDialog", false);
			this.$store.commit("SET_FAMILY_TRIGGER");
		},
	},
};
</script>