<template>
	<v-card
		v-if="show()"
		class="mx-auto mx-0 pa-0"
		elevation="0"
		max-width="550"
		tile
		v-intersect="{
			handler: onIntersect,
			options: {
				threshold: [0],
			},
		}"
		:min-height="minHeight"
	>
		<div v-show="hideFlag">
			<Author :author="item.author" :sk="item.sk" :date="getDate(item)" />

			<Media
				:images="item.images || []"
				:youtube="item.youtube || ''"
				:linkUrl="item.linkUrl || ''"
				:sn="item.sk"
				:autoplay="this.autoplay"
			/>

			<v-card-text
				min-height="150"
				:class="{
					'justify-center': true,
					'article-desc': true,
					default: showMore,
					expanded: !showMore,
				}"
				v-if="item.message"
				v-html="getMessage(item.message)"
			></v-card-text>
			<v-btn
				text
				v-if="hasMore && showMore"
				@click="showMore = !showMore"
				style="color: #999"
				class="btn-more-view"
				>더보기</v-btn
			>
			<!-- <v-divider></v-divider> -->

			<CommentAction
				:sk="item.sk"
				:images="item.images || []"
				:message="item.message"
			/>
			<div v-if="item.sns">SNS : {{ item.sns }}</div>

			<!-- <v-divider v-if="item.comments.length > 0"></v-divider> -->
			<Comment :sk="item.sk" :recentReply="item.recentReply" />
		</div>
	</v-card>
</template>

<script>
import Author from "@/components/common/AuthorType1.vue";
import Media from "@/components/common/Media.vue";
import CommentAction from "@/components/article/CommentAction.vue";
import Comment from "@/components/article/Comment.vue";

export default {
	name: "ArticleFrame",
	props: {
		item: Object,
	},
	components: {
		Author,
		Media,
		CommentAction,
		Comment,
	},
	data() {
		return {
			firstTime: true,
			hideFlag: true,
			minHeight: 200,
			hasMore: false,
			showMore: false,
			autoplay: false,
		};
	},
	computed: {
		channelInfo() {
			// FIXME 글 모아보기 시 params.channel로 체크 불가해지기 때문에 다른 방식으로 channel 정보 받아야 함
			return this.$store.state.channel.items.find(
				(e) => e.code == this.$route.params.channel
			);
		},
	},
	methods: {
		getThumb(thumb) {
			return thumb ? thumb : this.channelInfo.image.thumb;
		},
		getName(name) {
			return name ? name : this.channelInfo.title;
		},
		getMessage(message) {
			const msgUrl = this.convertUrlToLink(message);
			return msgUrl.replace(
				/#([0-9a-zA-Z가-힣]*)/g,
				'<a class="hash-tag" href="/tag/$1">#$1</a>'
			);
		},
		getDate(item) {
			return item.resDate ? item.resDate : item.regDate;
		},
		// 현재 시각 이전 글만 공개
		show() {
			return this.getDate(this.item) <= Date.now();
		},
		onIntersect(entries) {
			this.minHeight = this.$el.clientHeight;
			// setTimeout(()=>{
			this.hideFlag = entries[0].isIntersecting;
			// }, 1000);
			if (entries[0].isIntersecting) {
				this.$emit("loadingChk", this.item.sk);
				this.autoplay = true;
			} else {
				this.autoplay = false;
			}
		},
		showMoreText() {
			if (!this.$el.querySelector) {
				return;
			}
			let el = this.$el.querySelector(".article-desc");
			let h = el.scrollHeight;

			if (h > 100) {
				this.hasMore = true;
				this.showMore = true;
			}
		},
		// deleteArticle(sk) {
		// 	this.$emit("deleteArticle", sk);
		// },
		convertUrlToLink(message) {
			const regex =
				//eslint-disable-next-line
				/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/gi;
			const str = message;

			const subst = '<a class="linkUrl" href="$&" target="_blank">$&</a>';
			const result = str.replace(regex, subst);
			return result;
		},
	},
	mounted() {
		this.showMoreText();
	},
};
</script>

<style lang="scss">
.v-application a.hash-tag {
	color: #5f0cba;
	font-size: 14px;
	text-decoration: none;
}

.article-desc {
	padding: 8px 16px 0px;
	line-height: 1.3125em;
	width: 100%;
	display: block;
	display: -webkit-box;
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;

	white-space: pre-line;
	&.default {
		-webkit-line-clamp: 5;
	}
	&.expanded {
		-webkit-line-clamp: 9999;
	}
}
.btn-more-view {
	font-weight: normal;
}
</style>
