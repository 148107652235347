<template>
	<v-list-item class="card-info">
		<router-link to="/profile">
			<!-- 라우터링크는 내 글일경우만 링크가 동작하도록 수정 -->
			<v-list-item-avatar size="48">
				<v-img :src="thumbnail" :alt="nickname"></v-img>
			</v-list-item-avatar>
		</router-link>
		<v-list-item-content>
			<v-list-item-title>{{ nickname }}</v-list-item-title>
			<v-list-item-subtitle>{{
				formatDate(date, "type1")
			}}</v-list-item-subtitle>
		</v-list-item-content>
		<v-bottom-sheet v-model="sheet">
			<template v-slot:activator="{ on }">
				<v-list-item-action v-if="adminFlag">
					<v-btn icon v-on="on">
						<IconMoreHoriz />
					</v-btn>
				</v-list-item-action>
			</template>
			<v-sheet class="admin-sheet text-center">
				<div class="admin-top"></div>
				<v-list>
					<v-list-item link class="admin-other text-left">
						<v-list-item-title @click="editArticle()"
							>게시글 수정</v-list-item-title
						>
					</v-list-item>
					<v-list-item link class="admin-other text-left">
						<v-list-item-title @click="clickNoticeBtn()">
							{{ noticeStr }}
						</v-list-item-title>
					</v-list-item>

					<v-list-item link class="admin-other text-left">
						<v-list-item-title @click="deleteArticle()"
							>게시글 삭제</v-list-item-title
						>
					</v-list-item>
				</v-list>
			</v-sheet>
		</v-bottom-sheet>
		<v-dialog v-if="!isNotice" v-model="dialog" persistent max-width="290">
			<v-card class="popup-box">
				<v-card-text class="pt-6">
					해당 게시글을 공지로 등록하시겠습니까?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="btn-cancel" text @click="closeDialog">
						취소
					</v-btn>
					<v-btn color="btn-confirm" text @click="setNotice">
						확인
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-list-item>
</template>

<script>
import _ from "lodash";
import { setNotice, deleteArticle, deleteArticleNotice } from "@/api/article";
import IconMoreHoriz from "../icons/svg/more_horiz.svg";

export default {
	name: "AuthorType1",
	components: {
		IconMoreHoriz,
	},
	props: {
		author: String,
		sk: String,
		date: Number,
	},
	data() {
		return {
			sheet: false,
			dialog: false,
			onSubmitflag: false,
		};
	},
	computed: {
		member() {
			return this.$store.state.member[this.author];
		},
		thumbnail() {
			return this.member
				? this.member.thumb
				: "https://gspingpong.s3.ap-northeast-2.amazonaws.com/images/unknown/001.png";
		},
		nickname() {
			return this.member ? this.member.nickname : "noname";
		},
		isNotice() {
			let notices = this.$store.state.notices[
				this.$store.state.channel.selected
			]
				? this.$store.state.notices[this.$store.state.channel.selected]
						.items || []
				: [];
			let noticeIndex = _.findIndex(notices, { sk: this.sk });
			return noticeIndex >= 0;
		},
		isDeletable() {
			return this.isAuthor(this.author) || this.adminFlag;
		},
		noticeStr() {
			return this.isNotice ? "게시글 공지 해제" : "게시글 공지 등록";
		},
	},
	methods: {
		/* 공지 등록 및 해제 버튼 클릭 시 다음 액션 설정 */
		clickNoticeBtn() {
			if (this.isNotice) {
				this.sheet = false;
				this.dialog = false;
				this.unsetNotice();
			} else {
				this.dialog = true;
			}
		},
		closeDialog() {
			this.sheet = false;
			this.dialog = false;
		},
		async setNotice() {
			this.closeDialog();
			await setNotice(this.author, this.sk);
			let articles = this.$store.state.articles[
				this.$store.state.channel.selected
			]
				? this.$store.state.articles[this.$store.state.channel.selected]
						.items || []
				: [];
			let articleIndex = _.findIndex(articles, { sk: this.sk });
			let item = JSON.parse(
				JSON.stringify(
					this.$store.state.articles[
						this.$store.state.channel.selected
					].items[articleIndex]
				)
			);
			this.$store.state.notices[
				this.$store.state.channel.selected
			].items.unshift(item);

			articles.splice(articleIndex, 1);
			// 공지 등록 시 일반글에서 삭제

			this.snackbar(
				"commonSnackbar",
				"게시글이 공지로 등록되었습니다.",
				"check",
				56
			);
			if (document.getElementById("scrolling-techniques-4")) {
				document.getElementById("scrolling-techniques-4").scrollTop = 0;
			}
		},
		async unsetNotice() {
			this.closeDialog();
			await deleteArticleNotice(this.author, this.sk);
			let notices = this.$store.state.notices[
				this.$store.state.channel.selected
			]
				? this.$store.state.notices[this.$store.state.channel.selected]
						.items || []
				: [];
			let noticeIndex = _.findIndex(notices, { sk: this.sk });
			notices.splice(noticeIndex, 1);
			this.snackbar(
				"commonSnackbar",
				"게시글이 공지 해제되었습니다.",
				"check",
				56
			);
		},
		async editArticle() {
			if (!this.adminFlag) {
				this.snackbar(
					"commonSnackbar",
					"수정 권한이 없습니다.",
					"info",
					56
				);
				return;
			}
			this.$router.push("/article/" + this.sk);
		},
		async deleteArticle() {
			if (!this.isDeletable) {
				this.snackbar(
					"commonSnackbar",
					"삭제 권한이 없습니다.",
					"info",
					56
				);
				return;
			}
			if (this.onSubmitflag) {
				return;
			}
			this.onSubmitflag = true;
			let result = await deleteArticle(this.author, this.sk);

			if (result.status == "success") {
				this.sheet = false;
				this.snackbar(
					"commonSnackbar",
					"삭제가 완료되었습니다.",
					"check",
					56
				);
				let notices =
					this.$store.state.notices[
						this.$store.state.channel.selected
					].items;
				let noticeIndex = _.findIndex(notices, { sk: this.sk });
				if (noticeIndex !== -1) {
					notices.splice(noticeIndex, 1);
				} else {
					let articles =
						this.$store.state.articles[
							this.$store.state.channel.selected
						].items;
					let articleIndex = _.findIndex(articles, { sk: this.sk });
					articles.splice(articleIndex, 1);
					//this.$emit("deleteArticle", this.sk);
				}
			} else if (result == undefined || result.status == "fail") {
				this.deleteReplyDialog = false;
				this.snackbar(
					"commonSnackbar",
					"게시글 삭제 중 오류가 발생하였습니다. 새로고침 후 다시 시도해 주세요."
				);
			}
			this.onSubmitflag = false;
		},
	},
	created() {
		this.$store.dispatch("GET_MEMBER_INFO", {
			type: "sn",
			sn: this.author,
		});
	},
};
</script>
<style scoped lang="scss">
.admin-sheet::v-deep {
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	.admin-top {
		position: relative;
		padding: 30px 0 0;
		font-size: 16px;
		font-weight: 600;
		color: #191923;
		&:before {
			content: "";
			position: absolute;
			top: 8px;
			left: 50%;
			margin-left: -20px;
			display: block;
			width: 40px;
			height: 4px;
			border-radius: 4px;
			background-color: rgba(149, 149, 178, 0.3);
		}
	}
	.admin-other {
		margin: 0 24px;
		padding: 0;
	}
}

.popup-box {
	.v-card__text {
		font-size: 16px;
		color: #424252;
		line-height: 24px;
	}
	&.v-sheet.v-card {
		border-radius: 10px;
	}
	.v-card__actions > .v-btn.v-btn {
		min-width: auto;
		height: 40px;
		padding: 0 14px;
		color: #191923;
		font-size: 14px;
		&.btn-confirm {
			font-weight: 600;
		}
	}
}

.snackbar-noti1::v-deep {
	z-index: 300;
	position: fixed;
	bottom: 10px;
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		margin: 16px;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
	}
}
.card-info {
	border-bottom: 1px solid #eaecf5;
	padding: 0;
	margin: 0 16px;
}
</style>
