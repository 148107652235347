var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show())?_c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:({
		handler: _vm.onIntersect,
		options: {
			threshold: [0],
		},
	}),expression:"{\n\t\thandler: onIntersect,\n\t\toptions: {\n\t\t\tthreshold: [0],\n\t\t},\n\t}"}],staticClass:"mx-auto mx-0 pa-0",attrs:{"elevation":"0","max-width":"550","tile":"","min-height":_vm.minHeight}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hideFlag),expression:"hideFlag"}]},[_c('Author',{attrs:{"author":_vm.item.author,"sk":_vm.item.sk,"date":_vm.getDate(_vm.item)}}),_c('Media',{attrs:{"images":_vm.item.images || [],"youtube":_vm.item.youtube || '',"linkUrl":_vm.item.linkUrl || '',"sn":_vm.item.sk,"autoplay":this.autoplay}}),(_vm.item.message)?_c('v-card-text',{class:{
				'justify-center': true,
				'article-desc': true,
				default: _vm.showMore,
				expanded: !_vm.showMore,
			},attrs:{"min-height":"150"},domProps:{"innerHTML":_vm._s(_vm.getMessage(_vm.item.message))}}):_vm._e(),(_vm.hasMore && _vm.showMore)?_c('v-btn',{staticClass:"btn-more-view",staticStyle:{"color":"#999"},attrs:{"text":""},on:{"click":function($event){_vm.showMore = !_vm.showMore}}},[_vm._v("더보기")]):_vm._e(),_c('CommentAction',{attrs:{"sk":_vm.item.sk,"images":_vm.item.images || [],"message":_vm.item.message}}),(_vm.item.sns)?_c('div',[_vm._v("SNS : "+_vm._s(_vm.item.sns))]):_vm._e(),_c('Comment',{attrs:{"sk":_vm.item.sk,"recentReply":_vm.item.recentReply}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }