<template>
	<v-card tile elevation="0">
		<LoginDialog
			:loginDialog="loginDialog"
			:channel="channelInfo.code"
			@closeLoginDialog="closeLoginDialog"
		/>
		<BeFamilyDialog
			:beFamilyDialog="beFamilyDialog"
			:channelName="channelInfo.title"
			@closeFamilyDialog="closeFamilyDialog"
		/>
		<v-list three-line dense class="comment-box pa-0">
			<template v-if="reply.message">
				<v-divider class="mx-4"></v-divider>
				<v-list-item dense>
					<v-list-item-avatar size="32" class="mr-2">
						<v-img :src="member ? member.thumb : ''"></v-img>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title
							class="mt-2 mb-1 d-flex align-center"
						>
							{{ member ? member.nickname : "" }}
							<!-- 공지 뱃지 추가 -->
							<Badge
								:commentAdminFlag="
									member ? member.adminFlag : 'N'
								"
								:kind="reply.kind"
								:author="reply.author"
							></Badge>
						</v-list-item-title>
						<v-list-item-subtitle
							v-html="messageConvert"
						></v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-btn text @click="toComment" class="btn-all-comment"
					>댓글 모두 보기</v-btn
				>
			</template>
		</v-list>
		<v-bottom-sheet v-model="sheet">
			<template v-slot:activator="{ on }">
				<v-divider class="divider10"></v-divider>
				<v-card tile elevation="0">
					<v-card-actions class="py-0">
						<v-spacer></v-spacer>
						<v-btn
							color="#191923"
							class="font-weight-medium btn-write"
							dark
							depressed
							:v-on="recentReply ? '' : on"
							text
							@click="replyAction"
						>
							{{ replyStr }}
						</v-btn>
					</v-card-actions>
				</v-card>
				<v-divider class="mx-4"></v-divider>
			</template>
			<v-sheet class="text-center">
				<v-card elevation="0" class="pa-0" style="background: #fff">
					<v-col
						cols="12"
						sm="12"
						md="12"
						class="px-0 py-2"
						ref="replyArea"
					>
						<v-card-actions class="pa-0 align-end">
							<v-img
								max-width="32"
								max-height="32"
								class="comment-profile"
								:src="this.$store.state.myInfo.thumb"
							></v-img>
							<div class="comment-form">
								<v-textarea
									v-model="message"
									placeholder="댓글 쓰기..."
									color="#191923"
									outlined
									flat
									solo
									rows="1"
									auto-grow
									ref="replyTextarea"
								></v-textarea>
								<v-chip
									v-if="chip1"
									class="ma-1 ml-3"
									color="#F5F6FA"
									small
									close
									@click:close="chip1 = false"
								>
									{{ file.name }}
								</v-chip>
							</div>
							<v-file-input
								v-model="file"
								:rules="rules"
								small-chips
								show-size
								accept="image/png, image/jpeg"
								class="mt-0 mb-1 pt-0 btn-add-file"
							></v-file-input>
							<v-btn
								:loading="loading"
								:disabled="loading"
								text
								min-width="28"
								class="ml-0 px-2 btn-comment-submit"
								@click="writeReply"
								>게시</v-btn
							>
						</v-card-actions>
					</v-col>
				</v-card>
			</v-sheet>

			<!-- 댓글쓰기 사전동의 메세지  -->
			<v-snackbar
				:timeout="3000"
				v-model="warningFlag"
				absolute
				bottom
				dark
				color="#fff"
				left
				text
				class="mb-14 snackbar-noti1"
			>
				<v-img
					max-height="24"
					max-width="24"
					class="mr-4"
					src="../icons/icon_comment_noti.png"
				></v-img>
				<div>
					{{ snackbarMessage }}
				</div>
			</v-snackbar>
		</v-bottom-sheet>
	</v-card>
</template>

<script>
import { writeReply, replyItem } from "@/api/reply";
import Badge from "@/components/comment/Badge.vue";
import BeFamilyDialog from "../common/FamilyDialog.vue";
import LoginDialog from "../common/LoginDialog.vue";
export default {
	name: "Comment",
	components: {
		Badge,
		BeFamilyDialog,
		LoginDialog,
	},
	props: {
		sk: String,
		recentReply: String,
	},
	data() {
		return {
			sheet: false,
			loading: false,
			chip1: false,
			message: "",
			file: undefined,
			rules: [
				(value) => {
					if (value) {
						return value.size > 2000000
							? "File size should be less than 2 MB!"
							: true;
					}
					return true;
				},
			],
			reply: {},
			warningFlag: true,
			snackbarMessage:
				"카페 성격에 맞지 않는 글은 사전 동의 없이 삭제될 수 있으니 양해 부탁드립니다.",
			beFamilyDialog: false,
			loginDialog: false,
		};
	},
	computed: {
		member() {
			return this.$store.state.member[this.reply.author];
		},
		replyStr() {
			return this.recentReply
				? "댓글 쓰기"
				: "이 글의 첫 댓글을 달아보세요";
		},
		channelInfo() {
			return this.$store.state.channel.items.find(
				(e) => e.code == this.$store.state.channel.selected
			);
		},
		messageConvert() {
			return (this.reply.message || "")
				.replace(/&/gi, "&amp;")
				.replace(/#/gi, "&#035;")
				.replace(/\(/gi, "&#040;")
				.replace(/\)/gi, "&#041;")
				.replace(/</gi, "&lt;")
				.replace(/>/gi, "&gt;")
				.replace(/"/gi, "&quot;")
				.replace(/'/gi, "&apos;")
				.replace(/\n/gi, "<br />");
		},
	},
	watch: {
		file: function () {
			if (this.file) {
				this.chip1 = true;
			} else {
				this.chip1 = false;
			}
		},
		chip1: function () {
			if (!this.chip1) {
				this.file = undefined;
			}
		},
	},
	methods: {
		async writeReply() {
			let height = 58;

			if (this.loginFlg == true) {
				height = this.getReplyHeight();
			}
			let snackbarMessage = this.loginFlag
				? this.message
					? ""
					: "글은 반드시 등록해주셔야 합니다."
				: "로그인 후 글 등록 가능합니다.";
			if (snackbarMessage) {
				this.snackbar(
					"commonSnackbar",
					snackbarMessage,
					"check",
					height
				);
				return;
			}

			this.loading = true;
			let params = {
				pk: this.sk,
				message: this.message,
				files: this.file ? [this.file] : [],
				channel: this.$store.state[this.$route.params.channel],
			};
			const response = await writeReply(params);
			if (response.pk) {
				this.logWrite("replyWrite", "Click_커뮤니티_댓글작성_피드", {
					channel: this.$route.params.channel,
					article: this.sk,
				});
				this.clickTrac("424848", this.$route.params.channel);
				this.$set(this.$store.state.article[this.sk], "replyCount", 1);
				// 공지글/일반글 recentReply 설정
				const replyOnNotice = this.$store.state.notices[
					this.$store.state.channel.selected
				].items.find((e) => e.sk === this.sk);
				if (replyOnNotice) {
					this.$set(replyOnNotice, "recentReply", response.sk);
				} else {
					this.$set(
						this.$store.state.articles[
							this.$store.state.channel.selected
						].items.find((e) => e.sk === this.sk),
						"recentReply",
						response.sk
					);
				}
				this.$store.state.countCheck = true;
				this.reply = response;
				this.sheet = false;
				this.snackbarMessage = "등록이 완료되었습니다.";
				this.snackbar(
					"commonSnackbar",
					this.snackbarMessage,
					"check",
					this.getReplyHeight()
				);
				this.message = "";
				this.file = undefined;
			} else if (response.code == "ERROR") {
				this.snackbarMessage = response.message;
				this.snackbar(
					"commonSnackbar",
					this.snackbarMessage,
					"error",
					this.getReplyHeight()
				);
			} else {
				this.snackbarMessage = "잠시 후 다시 시도해주세요";
				this.snackbar(
					"commonSnackbar",
					this.snackbarMessage,
					"error",
					this.getReplyHeight()
				);
			}
			this.loading = false;
		},
		replyAction() {
			if (this.recentReply) {
				//로그인 여부? 봐야할지
				if (this.loginFlag == false) {
					this.loginDialog = true;
				} else if (
					this.$store.state.myInfo.family[
						window.location.pathname.split("/")[2]
					] == "Y"
				) {
					this.$router.push({
						name: "Comment",
						params: { articleSn: this.sk, focus: true },
					});
				} else {
					this.beFamilyDialog = true;
				}
			} else {
				if (!this.loginFlag) {
					this.snackbar(
						"commonSnackbar",
						"로그인 후 댓글 등록 가능합니다.",
						"check",
						56
					);
					return;
				} else {
					if (
						this.$store.state.myInfo.family[
							window.location.pathname.split("/")[2]
						] == "Y"
					) {
						this.sheet = true;
						setTimeout(() => {
							this.$refs.replyTextarea.focus();
						}, 500);
					} else {
						this.beFamilyDialog = true;
					}
				}
			}
		},

		toComment() {
			if (
				this.$store.state.myInfo.family[
					window.location.pathname.split("/")[2]
				] == "Y"
			) {
				this.$router.push("/comment/" + this.sk);
			} else if (this.loginFlag == false) {
				this.loginDialog = true;
			} else {
				this.beFamilyDialog = true;
			}
		},

		getReplyHeight() {
			if (this.loginFlag == false) {
				return 56;
			}
			let reHeight = this.$refs.replyArea.scrollHeight;
			return reHeight;
		},

		closeFamilyDialog() {
			this.beFamilyDialog = false;
		},

		closeLoginDialog() {
			this.loginDialog = false;
		},
	},

	async mounted() {
		if (this.recentReply) {
			// await this.$store.dispatch("REPLY_ITEM", {
			// 	pk: this.sk,
			// 	sk: this.recentReply,
			// });
			// this.reply =
			// 	this.$store.state["replyItem_" + this.sk][this.recentReply];
			this.reply = await replyItem(this.sk, this.recentReply);
			await this.$store.dispatch("GET_MEMBER_INFO", {
				type: "sn",
				sn: this.reply.author,
			});
		}
	},
};
</script>

<style scoped lang="scss">
.more {
	-webkit-line-clamp: initial;
}
.theme--light.v-divider::v-deep {
	border-color: #f9fafc;
}
.theme--dark.v-btn:focus::before {
	opacity: 0 !important;
}

.divider10 {
	border-width: 5px;
}
.v-list--dense .v-list-item {
	.v-list-item__title {
		font-size: 1rem;
		font-weight: 600;
		color: #191923;
		letter-spacing: -0.4px;
	}
	.v-list-item__subtitle {
		font-size: 14px;
		color: #191923 !important;
		line-height: 20px;
		-webkit-line-clamp: 5;
		font-weight: 400;
	}
}
.comment-box::v-deep {
	.v-list-item:first-child {
		border-top: 1px solid #eaecf5;
	}
	.button-box {
		color: #999;
	}
	.v-btn__content {
		letter-spacing: -0.3px;
		color: #999;
		font-weight: normal;
	}
	.btn-all-comment {
		margin: -15px 0 5px 40px;
	}
	.theme--dark.v-btn:focus::before {
		opacity: 0 !important;
	}
}
.v-chip.v-size--small {
	height: 24px;
	font-size: 12px;
	font-weight: 600;
	padding: 0 8px;
}

// 댓글쓰기 폼
.comment-profile::v-deep {
	margin: 0 8px 6px 12px;
	.v-image__image {
		border-radius: 50%;
	}
}
.comment-form::v-deep {
	flex: 1 0 auto;
	border-radius: 24px;
	min-height: 40px;
	align-items: flex-end !important;
	border: 1px solid #d2d4e5;
	text-align: left;
	&:focus,
	&:active,
	&:hover {
		border-color: #191923;
	}
	textarea {
		max-height: 84px;
		overflow: auto;
		border: 0;
	}
	.v-input__control {
		min-height: 40px !important;
	}
	.v-input__control > .v-input__slot {
		min-height: 40px;
		padding: 0 9px 0 12px !important;
		margin-bottom: 0 !important;
		background-color: transparent !important;
		.v-text-field__slot textarea {
			height: 28px;
			min-height: 28px;
			margin: 6px 0;
			outline: none;
		}
	}
	&.v-text-field--outlined.v-input--is-focused fieldset {
		border-width: 1px;
	}
	.v-icon.v-icon.v-icon--link {
		position: absolute;
		bottom: 10px;
	}
	.v-input__append-inner {
		.mdi-close::before {
			content: "";
			width: 20px;
			height: 20px;
			background-image: url("../icons/close_alt_fill.png");
			background-size: 100%;
		}
	}
	.v-text-field__details {
		display: none;
	}
	.v-text-field--outlined fieldset {
		border-width: 0;
	}
	.v-chip.v-size--small {
		position: relative;
		font-size: 16px;
		font-weight: 400;
		color: #9595b2;
		overflow: initial;
	}
	.v-chip .v-chip__content {
		display: inline-block;
		width: 125px;
		line-height: 28px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.v-chip .v-chip__close.v-icon {
		position: absolute;
		top: -8px;
		right: 0;
		max-width: 16px;
		max-height: 16px;
		margin-top: 0;
		margin-right: -7px;
	}
}

.comment-inp::v-deep {
	margin-top: 0;
	padding: 0 0 0 16px;
	height: 40px;
	border: 1px solid #d2d4e5;
	border-radius: 24px;
	.v-input__control {
		height: 40px;
		.v-input__slot:after {
			border: 0;
		}
		.v-input__slot:before {
			border: 0;
		}
		.v-text-field__details {
			display: none;
		}
		input {
			min-height: 40px !important;
		}
		.inp-clear {
			margin: 4px 10px 0 0;
		}
	}
}

// 첨부파일 아이콘
.btn-add-file::v-deep {
	width: 24px;
	margin-left: 8px;
	flex: 0 1 auto;
	.mdi-paperclip::before {
		content: "";
		width: 20px;
		height: 20px;
		background-image: url("../icons/file_add.png");
		background-size: cover;
	}
	.v-input__control {
		display: none;
	}
}

.btn-comment-submit {
	margin-bottom: 2px;
}

.v-sheet.v-snack__wrapper {
	border-radius: 12px;
}
.snackbar-noti1::v-deep {
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
		letter-spacing: -0.4px;
	}
}
.snackbar-noti2::v-deep {
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		&.v-sheet {
			border-radius: 0;
		}
		&.theme--dark {
			margin: 0;
			background-color: #f9fafc;
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
		letter-spacing: -0.4px;
		padding: 6px 16px 6px;
	}
}

// 레이어 팝업
.popup-box {
	.v-card__text {
		font-size: 16px;
		color: #424252;
		line-height: 24px;
		letter-spacing: -0.4px;
	}
	&.v-sheet.v-card {
		border-radius: 10px;
	}
	.v-card__actions > .v-btn.v-btn {
		min-width: auto;
		height: 40px;
		padding: 0 14px;
		color: #191923;
		letter-spacing: -0.4px;
		font-size: 14px;
		&.btn-confirm {
			font-weight: 600;
		}
	}
}
.btn-write {
	font-weight: 600 !important;
	font-size: 13px;
	height: 32px !important;
}
</style>
