<template>
	<v-card class="mx-auto text-center" elevation="0" color="white" tile>
		<v-divider class="divider10"></v-divider>
		<v-img
			:src="channelInfo.image.main"
			class="white--text align-top channel-img"
		>
			<v-card-title class="justify-center channel-title">
				{{ channelInfo.title }}
			</v-card-title>
			<v-card-text class="text-subtitle-1">
				{{ channelInfo.broadTime }}
			</v-card-text>

			<v-card-actions class="justify-center py-2">
				<v-chip
					class="mx-2 px-4"
					color="white"
					:href="channelInfo.shopLink"
					@click="
						logWrite('shopLink', 'Click_커뮤니티_방송샵', {
							channel: $route.params.channel,
						})
					"
				>
					<IconGoStore class="mr-1" />
					방송상품샵
				</v-chip>
				<v-chip class="mx-2 px-4" color="white" @click="familyDialog()">
					<IconSmile class="mr-1" />
					{{ familyStr() }}
				</v-chip>
			</v-card-actions>
			<div v-if="channelInfo.famCount > 0" class="text-body-2">
				가족수 {{ comma(channelInfo.famCount) }}
			</div>
		</v-img>

		<v-divider class="divider10"></v-divider>
		<v-card-text class="py-0">
			<v-chip-group class="tag-box">
				<v-chip
					v-for="(tag, index) in channelInfo.tags"
					:key="index"
					:to="'/tag/' + tag"
					@click="
						logWrite('tag', 'Click_커뮤니티_해시태그', {
							channel: $route.params.channel,
						})
					"
					label
					small
					class="ma-2"
					color="#f5f2fb"
					text-color="#5829bb"
				>
					<span class="tag-txt">#{{ tag }}</span>
				</v-chip>
			</v-chip-group>
		</v-card-text>
		<v-divider></v-divider>
		<LoginDialog
			:loginDialog="loginDialog"
			:channel="this.$route.params.channel"
			@closeLoginDialog="closeLoginDialog"
		></LoginDialog>
		<v-dialog v-model="setFamilyDialog" persistent max-width="290">
			<v-card class="popup-box">
				<v-card-text class="pt-6">
					{{ channelInfo.title }} 가족이 되신 것을 환영해요!
					<br />프로필의 사진과 별명을 설정해주세요! <br />(게시글의
					프로필 이미지를 클릭하시면 별명은 언제든지 변경할 수
					있습니다.)
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="btn-confirm" text @click="goToProfile()">
						지금 하러가기
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<!-- <v-dialog v-model="loading" persistent max-width="40">
			<v-progress-circular
				indeterminate
				color="blue"
			></v-progress-circular>
		</v-dialog> -->

		<v-dialog v-model="unsetFamilyDialog" persistent max-width="290">
			<v-card class="popup-box">
				<v-card-text class="pt-6">
					정말 가족해지 하시겠습니까? 해지 시 작성한 댓글은 자동으로
					삭제되지 않으며 카페 활동 및 이벤트 참여가 불가능합니다.
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="btn-cancel"
						text
						@click="unsetFamilyDialog = false"
					>
						취소
					</v-btn>
					<v-btn color="btn-confirm" text @click="unsetFamily()">
						확인
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<BeFamilyDialog
			:beFamilyDialog="beFamilyDialog"
			:channelName="channelInfo.title"
			@closeFamilyDialog="closeFamilyDialog"
		/>
	</v-card>
</template>

<script>
import { setFamilyInfo } from "@/api/member";
import IconGoStore from "../icons/svg/store.svg";
import IconSmile from "../icons/svg/smile.svg";
import LoginDialog from "../common/LoginDialog.vue";
import BeFamilyDialog from "../common/FamilyDialog.vue";

export default {
	data: () => ({
		loading: false,
		setFamilyDialog: false,
		unsetFamilyDialog: false,
		beFamilyDialog: false,
		loginDialog: false,
	}),
	computed: {
		channelInfo() {
			return this.$store.state.channel.items.find(
				(e) => e.code == this.$route.params.channel
			);
		},
		familyTrigger() {
			return this.$store.state.familyTrigger;
		},
	},
	watch: {
		channelInfo: function () {
			document.getElementById("scrolling-techniques-4").scrollTop = 0;
		},
		familyTrigger: function () {
			this.family();
		},
	},
	methods: {
		familyStr() {
			return this.$store.state.myInfo.family[this.channelInfo.code] == "Y"
				? "가족"
				: "가족되기";
		},
		async familyDialog() {
			if (this.loginFlag) {
				if (
					this.$store.state.myInfo.family[this.channelInfo.code] ===
					"Y"
				) {
					this.unsetFamilyDialog = true;
				} else {
					this.beFamilyDialog = true;
				}
			} else {
				this.loginDialog = true;
			}
		},
		login() {
			window.location.href =
				"https://m.gsshop.com/member/logIn.gs?returnurl=68747470733A2F2F70696e67706f6e672e677373686f702e636f6d";
		},
		async family() {
			this.loading = true;
			await setFamilyInfo("Y");
			this.$emit("joinFamily", true);
			this.loading = false;
			this.channelInfo.famCount += 1;
			await this.$store.commit("SET_FAMILY_INFO", {
				fmlyYn: "Y",
				fmlyCnt: this.channelInfo.famCount,
			});
			this.logWrite("doFamily", "Click_커뮤니티_가족되기", {
				channel: this.$route.params.channel,
			});

			if (this.$store.state.myInfo.nickname) {
				if (localStorage.getItem("nickChangFlag") != "N") {
					const regexs = [
						/고객 ?\d{6}/g,
						/핑퐁맨 ?\d{6}/g,
						/핑퐁왕 ?\d{6}/g,
						/핑퐁신 ?\d{6}/g,
						/핑퐁족 ?\d{6}/g,
						/핑퐁군 ?\d{6}/g,
						/핑퐁양 ?\d{6}/g,
						/핑퐁이 ?\d{6}/g,
					];
					regexs.some((regex) => {
						if (
							this.$store.state.myInfo.nickname.search(regex) >= 0
						) {
							localStorage.setItem("nickChangFlag", "Y");
							this.setFamilyDialog = true;
							return true;
						} else {
							localStorage.setItem("nickChangFlag", "N");
						}
					});
				} else {
					this.snackbar(
						"commonSnackbar",
						"가족되기 완료! 소통을 이어나가주세요.",
						"check"
					);
				}
			}
		},
		async unsetFamily() {
			this.channelInfo.famCount -= 1;
			await setFamilyInfo("N");
			await this.$store.commit("SET_FAMILY_INFO", {
				fmlyYn: "N",
				fmlyCnt: this.channelInfo.famCount,
			});
			this.$emit("joinFamily", false);
			this.unsetFamilyDialog = false;
			this.logWrite("doFamily", "Click_커뮤니티_가족해지", {
				channel: this.$route.params.channel,
			});
		},
		goToProfile() {
			localStorage.setItem("nickChangFlag", "N");
			this.$router.push("/profile");
		},
		closeLoginDialog() {
			this.loginDialog = false;
		},
		closeFamilyDialog() {
			this.beFamilyDialog = false;
		},
	},
	components: {
		IconGoStore,
		IconSmile,
		LoginDialog,
		BeFamilyDialog,
	},
};
</script>

<style scoped lang="scss">
.channel-title {
	display: inline-block;
	padding: 18px 0 0 0;
	font-size: 28px;
}
.text-subtitle-1 {
	opacity: 0.6;
	padding-bottom: 0;
	line-height: 1.2;
}
.text-body-2 {
	opacity: 0.6;
	letter-spacing: -0.025em !important;
}
.tag-txt {
	font-weight: 500;
}
.tag-box::v-deep {
	.v-slide-group__content {
		justify-content: center;
	}
}
.theme--light.v-divider::v-deep {
	border-color: #f9fafc;
}
.divider10 {
	border-width: 5px;
}
</style>
