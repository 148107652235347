<template>
	<section v-if="images.length || youtube">
		<v-carousel
			v-if="(images.length && youtube) || images.length > 1"
			:height="mediaHeight + (linkUrl ? 35 : 0)"
			hide-delimiter-background
			:show-arrows="false"
			ref="carousel"
			delimiter-icon=""
			class="media-carousel"
			v-touch="{
				left: () => swipe(),
				right: () => swipe(),
			}"
		>
			<v-badge
				color="rgba(0,0,0,0.5)"
				:content="currNum + '/' + (images.length + (youtube ? 1 : 0))"
				offset-x="40"
				offset-y="40"
				:style="imgSize()"
				class="media-badge"
			>
				<v-carousel-item v-if="youtube">
					<div
						class="fill-height multi-height"
						align="center"
						justify="center"
					>
						<vue-plyr
							v-if="youtube"
							ref="plyr"
							:style="vodSize()"
							:options="playerOptions"
						>
							<div
								data-plyr-provider="youtube"
								:data-plyr-embed-id="youtube"
							></div>
						</vue-plyr>
					</div>
				</v-carousel-item>
				<v-carousel-item v-for="(image, i) in images" eager :key="i">
					<div class="fill-height" align="center" justify="center">
						<div v-if="image">
							<v-img
								:src="imgSrc(image)"
								:style="imgSize()"
								aspect-ratio="1"
							></v-img>
						</div>
					</div>
				</v-carousel-item>
			</v-badge>
			<div v-if="linkUrl" class="go-link multi-link">
				<a :href="linkUrl" target="_blank">상품 자세히 보기</a>
			</div>
		</v-carousel>
		<div v-else class="fill-height" align="center" justify="center">
			<vue-plyr
				v-if="youtube"
				ref="plyr"
				:style="vodSize()"
				:options="playerOptions"
			>
				<div
					data-plyr-provider="youtube"
					:data-plyr-embed-id="youtube"
				></div>
			</vue-plyr>
			<div v-if="images[0]" :style="imgSize()">
				<v-img
					:src="imgSrc(images[0])"
					:style="imgSize()"
					aspect-ratio="1"
				></v-img>
			</div>
			<div v-if="linkUrl" class="go-link">
				<a :href="linkUrl" target="_blank">상품 자세히 보기</a>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Media",
	props: {
		images: Array,
		youtube: String,
		linkUrl: String,
		sn: String,
		autoplay: Boolean,
	},
	data() {
		return {
			mediaHeight: 0,
			mediaWidth: 0,
			currNum: 1,
		};
	},
	watch: {
		autoplay: function () {
			if (this.$refs.plyr) {
				if (this.autoplay) {
					this.player.play();
				} else {
					this.player.pause();
				}
				//this.player.togglePlay(this.autoplay);
			}
		},
	},
	computed: {
		player() {
			return this.$refs.plyr.player;
		},
		playerOptions: function () {
			return {
				title: "This is an example video",
				volume: 0.5,
				controls: ["play", "progress", "mute", "volume", "fullscreen"],
			};
		},
	},
	methods: {
		vodSize() {
			let width = window.innerWidth < 550 ? window.innerWidth : 550;
			return "width:" + width + "px; height:" + (width * 9) / 16 + "px;";
		},
		imgSize() {
			let width = window.innerWidth < 550 ? window.innerWidth : 550;
			return "width:" + width + "px; height:" + width + "px;";
		},
		imgSrc(src) {
			let type = "1p";
			src = src.replace(
				/gssnsplatformtest.s3.ap-northeast-2.amazonaws.com/,
				"d3fmyxhkmw016y.cloudfront.net/" +
					this.mediaWidth +
					"x" +
					this.mediaHeight +
					"/75/" +
					type
			);
			src = src.replace(
				/gspingpong.s3.ap-northeast-2.amazonaws.com/,
				"dhx9dskp083uc.cloudfront.net/" +
					this.mediaWidth +
					"x" +
					this.mediaHeight +
					"/75/" +
					type
			);
			return src;
		},
		swipe() {
			if (this.$refs.carousel.selectedIndex == -1) {
				this.currNum = 1;
			} else {
				this.currNum = this.$refs.carousel.selectedIndex + 1;
			}
		},
	},
	mounted() {
		if (this.youtube) {
			// this.player.on('event', (a) => console.log('event fired', a));
			this.mediaHeight = Math.ceil(
				(window.innerWidth > 550 ? 550 : window.innerWidth) +
					(44 * 9) / 16
			);
		} else {
			this.mediaHeight =
				(window.innerWidth > 550 ? 550 : window.innerWidth) + 24;
		}
		this.mediaWidth =
			(window.innerWidth > 550 ? 550 : window.innerWidth) + 24;
		if (this.$refs.plyr) {
			this.player.volume = 0.5;
			this.player.muted = true;
		}
	},
};
</script>

<style scoped lang="scss">
.number-badge {
	height: 23px;
}

.media-carousel::v-deep {
	position: relative;
	box-sizing: unset;
	.v-carousel__controls {
		box-sizing: border-box;
		height: 24px;
	}
	.v-badge__badge {
		box-sizing: border-box;
	}
	.v-carousel__controls {
		.v-btn--icon.v-size--small .v-icon {
			width: 6px;
			height: 6px;
			color: black;
		}
		// .v-btn
		.v-btn.v-btn--icon {
			width: 6px;
			height: 6px;
			margin: 3px;
			color: transparent !important;
			border: 1px solid #000 !important;
			background-color: #000 !important;
			opacity: 0.2;
		}
		// .v-btn--active
		.v-btn.v-btn--icon.v-btn--active {
			width: 8px;
			height: 8px;
			color: #6d73cc !important;
			border: 1px solid #6d73cc !important;
			background-color: #6d73cc !important;
			opacity: 1;
		}
	}
}
.media-badge {
	.v-badge__wrapper {
		.v-badge__badge {
			height: 23px;
		}
	}
}
.fill-height::v-deep {
	position: relative;
	.plyr--video {
		margin: 0 auto;
	}
	&.multi-height {
		margin-top: 0;
		height: 100vw;
		max-height: 550px;
		padding: 0;
	}
}

.go-link {
	// position: absolute;
	position: relative;
	box-sizing: border-box;
	bottom: 0;
	width: 100%;
	height: 35px;
	padding: 0 15px;
	line-height: 35px;
	background: linear-gradient(
		-45deg,
		rgba(82, 92, 233, 0.4),
		rgba(82, 92, 233, 0.8)
	);
	text-align: left;
	font-size: 16px;
	a {
		display: inline-block;
		width: 100%;
		color: #fff;
		text-decoration: none;
	}
	&:before {
		content: "";
		display: block;
		width: 24px;
		height: 24px;
		position: absolute;
		top: 5px;
		right: 5px;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.12598 18.1244C8.12598 17.9324 8.19898 17.7404 8.34548 17.5939L14.0655 11.8744L8.34548 6.15489C8.19898 6.00839 8.12598 5.81639 8.12598 5.62439C8.12598 5.43239 8.19898 5.24039 8.34548 5.09389C8.63848 4.80139 9.11348 4.80139 9.40648 5.09389L15.6555 11.3429C15.802 11.4894 15.875 11.6814 15.875 11.8734V11.8744V11.8754C15.875 12.0674 15.802 12.2594 15.6555 12.4054L9.40648 18.6549C9.11348 18.9474 8.63848 18.9474 8.34548 18.6549C8.19898 18.5084 8.12598 18.3164 8.12598 18.1244Z' fill='%23ffffff'/%3E%3C/svg%3E")
			no-repeat;
	}
}
</style>
