import { render, staticRenderFns } from "./CommentAction.vue?vue&type=template&id=76468743&scoped=true&"
import script from "./CommentAction.vue?vue&type=script&lang=js&"
export * from "./CommentAction.vue?vue&type=script&lang=js&"
import style0 from "./CommentAction.vue?vue&type=style&index=0&id=76468743&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76468743",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBottomSheet,VBtn,VCardActions,VCol,VContainer,VImg,VRow,VSheet,VSnackbar})
