<template>
	<v-dialog v-model="getFlag" persistent max-width="290">
		<v-card class="popup-box">
			<v-card-text class="pt-6">
				로그인 후 이용하실 수 있습니다.
				<br />
				로그인하시고 즐거운 소통을 이어나가주세요!
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="btn-confirm" text @click="login">
					로그인하기
				</v-btn>
				<v-btn color="btn-confirm" text @click="unsetDialog">
					닫기
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>
// import IconArrowRight from "../icons/svg/chevron_right.svg";

export default {
	name: "LoginDialog",
	components: {},
	props: {
		loginDialog: Boolean,
		channel: String,
	},
	computed: {
		getFlag() {
			return this.loginDialog;
		},
		getChannel() {
			return this.channel;
		},
	},
	methods: {
		unsetDialog() {
			this.$emit("closeLoginDialog", false);
		},
		login() {
			window.location.href =
				"https://m.gsshop.com/member/logIn.gs?returnurl=" +
				Buffer.from(window.location.origin + "/channel/").toString(
					"hex"
				) +
				Buffer.from(this.getChannel).toString("hex");
		},
	},
};
</script>