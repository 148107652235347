<template>
	<section style="padding-bottom: 60px">
		<Main @joinFamily="joinFamily" />
		<ArticleFrame
			v-if="this.$route.params.articleSn && shareItem.sk"
			:key="$route.params.channel + 'share_' + shareItem.sk"
			:item="shareItem"
			@loadingChk="no"
		/>
		<ArticleFrame
			v-for="notice in notices"
			:key="$route.params.channel + 'notice_' + notice.sk"
			:item="notice"
			@loadingChk="no"
		/>
		<v-skeleton-loader :loading="loading" :type="types" v-if="isFamily">
			<ArticleFrame
				v-for="item in itemsChunk"
				:key="$route.params.channel + 'article_' + item.sk"
				:item="item"
				@loadingChk="more"
			/>
			<ProgressCircle :remainFlag="remainFlag" />
		</v-skeleton-loader>
		<iframe style="display: none" ref="frame"></iframe>
	</section>
</template>

<script>
//import _ from "lodash";
import { shareItem } from "@/api";
import Main from "@/components/channel/Main.vue";
import ArticleFrame from "@/components/article/ArticleFrame.vue";
import ProgressCircle from "@/components/article/ProgressCircle.vue";

export default {
	name: "Articles",
	data() {
		return {
			shareItem: {},
			index: 0,
			loading: true,
			remainFlag: true,
			moreLoading: false,
			types: "list-item-avatar-two-line, image, paragraph, actions, list-item-avatar-two-line, image, paragraph, actions",
			family: {
				416860: "N",
				416861: "N",
				416862: "N",
				420782: "N",
			},
			isFamily: false,
		};
	},
	components: {
		Main,
		ArticleFrame,
		ProgressCircle,
	},
	computed: {
		channelCode() {
			return this.$route.params.channel;
		},
		notices() {
			return this.$store.state.notices[this.channelCode].items;
		},
		itemsChunk() {
			return this.$store.state.articles[this.channelCode].items;
		},
		lastEvaluatedKey() {
			return this.$store.state.articles[this.channelCode]
				.lastEvaluatedKey;
		},
		familyYn() {
			return this.$store.state.myInfo.family[this.channelCode];
		},
	},
	watch: {
		isFamily: async function (value) {
			if (value == true) {
				this.family[this.channelCode] = "Y";
				await this.$store.dispatch("FETCH_ITEMS");
				this.index = 0;
			} else if (value == false) {
				this.family[this.channelCode] = "N";
			}
		},
		channelCode: async function () {
			//console.log("채널 코드 변경" + this.channelCode);
			this.inflow();
			await this.$store.commit("SET_CHANNEL_CODE", this.channelCode);
			await this.$store.dispatch("NOTICE_ITEMS");
			let myInfo = await this.$store.dispatch("GET_FAMILY_INFO");
			if (this.$store.state.myInfo.sn == "") {
				myInfo = "N";
			}
			this.family[this.channelCode] = myInfo;

			if (myInfo === "Y") {
				await this.$store.dispatch("FETCH_ITEMS");
				this.isFamily = true;
				if (this.itemsChunk.length == 0) {
					await this.$store.dispatch("FETCH_MORE_ITEMS");
				}
			} else {
				this.isFamily = false;
			}
			this.index = 0;
		},
	},
	methods: {
		inflow() {
			var mseq = window.location.search;
			if (mseq.indexOf("mseq=") > 0) {
				if (mseq) mseq = mseq.split("mseq=")[1];
				if (mseq) mseq = mseq.split("&")[0];
				if (mseq) mseq = "&mseq=" + mseq;
			} else {
				mseq = "";
			}

			this.$refs.frame.contentWindow.location.replace(
				"https://event.gsshop.com/dummy/inflow.jsp?channel=" +
					this.$route.params.channel +
					mseq
			);
		},
		no() {},
		async more(sk) {
			//아이템청크에 게시글이 하나도 없을 때 (모두 공지로 설정된 드문 경우) || 아이템청크에 담긴 게시글을 모두 조회했을 때 LEK를 통해 게시글을 추가로 조회한다
			if (
				this.itemsChunk.length == 0 ||
				this.itemsChunk[this.itemsChunk.length - 1].sk == sk
			) {
				//20-N개 다 봤으니 다음 20개 더 가져오기
				if (this.lastEvaluatedKey) {
					this.remainFlag = true;
					if (this.moreLoading) {
						return;
					}
					this.moreLoading = true;
					await this.$store.dispatch("FETCH_MORE_ITEMS");
					if (!this.lastEvaluatedKey) {
						this.remainFlag = false;
					}
					this.moreLoading = false;
				} else {
					this.remainFlag = false;
				}
			}
		},
		// deleteArticle(sk) {
		// 	let spliceCount = 0;
		// 	this.itemsChunk.some((d, i) => {
		// 		if (d.sk == sk) {
		// 			this.itemsChunk.splice(i, 1);
		// 			console.log("아이템청크 찾음");
		// 			spliceCount++;
		// 		}
		// 	});
		// 	this.index--;
		// },
		joinFamily(isFamily) {
			this.isFamily = isFamily;
		},
	},
	async created() {
		if (this.$route.params.articleSn) {
			this.shareItem = await shareItem(
				this.channelCode,
				this.$route.params.articleSn
			);
		}

		await this.$store.dispatch("NOTICE_ITEMS");
		let myFamilyInfo = await this.$store.dispatch("GET_FAMILY_INFO");
		this.family[this.channelCode] = myFamilyInfo;
		if (myFamilyInfo === "Y") {
			await this.$store.dispatch("FETCH_ITEMS");
			this.isFamily = true;
		}
	},
	mounted() {
		const loadingInterval = setInterval(() => {
			if (this.itemsChunk.length > 0) {
				clearInterval(loadingInterval);
				this.loading = false;
			}
		}, 100);
		setTimeout(() => {
			clearInterval(loadingInterval);
			this.loading = false;
			this.remainFlag = false;
		}, 5000);
		this.logWrite("pageView", "View_커뮤니티_방문", {
			channel: this.$route.params.channel,
		});
	},
};
</script>
